import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/components/HomeView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/page-not-found',
    alias: '*',
    meta: { emptyLayout: true, title: 'Страница не найдена' },
    component: () =>
      import(/* webpackChunkName: "p404" */ '../views/PageView404'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function getDocumentTitle(to) {
  const { title } = to.meta

  return title ? title : 'AkeRio'
}

router.afterEach(to => {
  document.title = getDocumentTitle(to)
})

export default router
