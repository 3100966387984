<template>
  <div>
    <header id="header" class="header fixed-top">
      <div
        class="container-fluid container-xl d-flex align-items-center justify-content-between"
      >
        <a href="/" class="logo d-flex align-items-center">
          <img src="@/assets/logo.svg" alt="Logo" />
          <img src="@/assets/logo-text.svg" alt="Logo text" />
        </a>

        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <a class="nav-link scrollto active" href="#home">Главная</a>
            </li>
            <li><a class="nav-link scrollto" href="#about">О нас</a></li>
            <li><a class="nav-link scrollto" href="#services">Услуги</a></li>
            <li>
              <a class="nav-link scrollto" href="#portfolio">Портфолио</a>
            </li>
            <!--<li><a class="nav-link scrollto" href="#team">Team</a></li>
          <li><a href="blog.html">Blog</a></li>
          <li class="dropdown">
            <a href="#"
              ><span>Drop Down</span> <i class="bi bi-chevron-down"></i
            ></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li class="dropdown">
                <a href="#"
                  ><span>Deep Drop Down</span>
                  <i class="bi bi-chevron-right"></i
                ></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li>-->
            <li><a class="nav-link scrollto" href="#contact">Контакты</a></li>
            <li>
              <a class="getstarted scrollto" href="https://hd.akerio.ru"
                >Войти</a
              >
            </li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
        <!-- .navbar -->
      </div>
    </header>

    <section id="home" class="hero d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up">
              Мы предлагаем современные решения для развития вашего бизнеса
            </h1>
            <h2 data-aos="fade-up" data-aos-delay="400">
              Мы - команда талантливых IT-специалистов
            </h2>
            <div data-aos="fade-up" data-aos-delay="600">
              <div class="text-center text-lg-start">
                <a
                  href="#contact"
                  class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Начать</span>
                  <i class="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            class="col-lg-5 hero-img"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <img src="@/assets/img/hero-img.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>

    <section id="about" class="features">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>О нас</h2>
          <p>Мы решаем поставленные задачи</p>
        </header>

        <div class="row">
          <div class="col-lg-6">
            <img src="@/assets/img/features.png" class="img-fluid" alt="" />
          </div>

          <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
            <div class="row align-self-center gy-4">
              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Сервис 24/7</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Стоимость услуг</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Гарантия</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Опыт работы</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Ответственность</h3>
                </div>
              </div>

              <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                <div class="feature-box d-flex align-items-center">
                  <i class="bi bi-check"></i>
                  <h3>Любовь к своей работе</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="services" class="recent-blog-posts">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>Услуги</h2>
          <p>Спектр предоставляемых услуг</p>
        </header>

        <div class="row">
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img">
                <img
                  src="@/assets/img/services/blog-1.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <span class="post-date">Web-поддержка</span>
              <h3 class="post-title">
                Поддержка и сопровождение сущесвующих сайтов
              </h3>
              <a href="#contact" class="readmore stretched-link mt-auto"
                ><span>Узнать больше</span><i class="bi bi-arrow-right"></i
              ></a>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img">
                <img
                  src="@/assets/img/services/blog-2.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <span class="post-date">Web-разработка</span>
              <h3 class="post-title">
                Разработка сайта, систем учета и отчетности
              </h3>
              <a href="#contact" class="readmore stretched-link mt-auto"
                ><span>Узнать больше</span><i class="bi bi-arrow-right"></i
              ></a>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img">
                <img
                  src="@/assets/img/services/blog-3.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <span class="post-date">IT-поддержка</span>
              <h3 class="post-title">
                Комплексный аутсорсинг и ИТ поддержка бизнеса
              </h3>
              <a
                href="https://it-box.pro/"
                target="_blank"
                class="readmore stretched-link mt-auto"
                ><span>Узнать больше</span><i class="bi bi-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="portfolio" class="team">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>Портфолио</h2>
          <p>Наши проекты</p>
        </header>

        <div class="row gy-4">
          <div
            class="col-lg-4 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="member">
              <div class="member-img">
                <img
                  src="@/assets/img/portfolio/pf-3.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Лендинг AkeRio</h4>
                <span>Сайт-визитка</span>
                <p>
                  Лендинг на основе Vue JS & Nest JS для рекламы и продвижения
                  услуг
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="member">
              <div class="member-img">
                <img
                  src="@/assets/img/portfolio/pf-2.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Лендинг IT-BOX.PRO</h4>
                <span>Сайт-визитка</span>
                <p>
                  Лендинг, созданный по заказу компании IT-BOX для рекламы и
                  продвижения услуг с использованием Nest JS
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div class="member">
              <div class="member-img">
                <img
                  src="@/assets/img/portfolio/pf-1.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Helpdesk IT-BOX.PRO</h4>
                <span>Система управления тикетами</span>
                <p>
                  Проект на основе laravel 8, созданный по заказу компании
                  IT-BOX для отчетности и учета рабочего времени
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" class="contact">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <header class="section-header">
          <h2>Контакты</h2>
          <p>Отправьте нам свой вопрос</p>
        </header>
        <div class="row gy-4">
          <!--<div class="col-lg-6">
            <div class="row gy-4">
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p>A108 Adam Street,<br />New York, NY 535022</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>+1 5589 55488 55<br />+1 6678 254445 41</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@example.com<br />contact@example.com</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-clock"></i>
                  <h3>Open Hours</h3>
                  <p>Monday - Friday<br />9:00AM - 05:00PM</p>
                </div>
              </div>
            </div>
          </div>-->
          <div class="col-lg-12">
            <form class="php-email-form" @submit.prevent="submitForm">
              <div class="row gy-4">
                <div class="col-md-6">
                  <input
                    v-model="data.name"
                    type="text"
                    class="form-control"
                    placeholder="Ваше имя"
                    required=""
                  />
                </div>

                <div class="col-md-6">
                  <input
                    v-model="data.email"
                    type="email"
                    class="form-control"
                    placeholder="Ваш Email"
                    required=""
                  />
                </div>

                <div class="col-md-12">
                  <input
                    v-model="data.subject"
                    type="text"
                    class="form-control"
                    placeholder="Тема сообщения"
                    required=""
                  />
                </div>

                <div class="col-md-12">
                  <textarea
                    v-model="data.message"
                    class="form-control"
                    rows="6"
                    placeholder="Текст сообщения"
                    required=""
                  ></textarea>
                </div>

                <div class="col-md-12">
                  Нажимая на кнопку,
                  <a href="#" @click.prevent="showPriv"
                    >вы даете согласие на обработку своих персональных данных</a
                  >
                </div>

                <div class="col-md-12 text-center">
                  <div class="error-message"></div>
                  <div class="sent-message" v-if="success">
                    Ваше сообщение отправлено. Спасибо!
                  </div>

                  <button
                    type="submit"
                    :disabled="success"
                    :class="{ disabled: success }"
                  >
                    Отправить
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <footer id="footer" class="footer">
      <!--<div class="footer-newsletter">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
              <h4>AkeRio новости</h4>
              <p>
                Подпишитесь на наши новости, чтобы быть в курсе послдених
                событий в AkeRio
              </p>
            </div>
            <div class="col-lg-6">
              <form action="" method="post">
                <input type="email" name="email" /><input
                  type="submit"
                  value="Подписаться"
                />
              </form>
            </div>
          </div>
        </div>
      </div>-->

      <div class="footer-top">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-5 col-md-12 footer-info">
              <a href="/" class="logo d-flex align-items-center">
                <img src="@/assets/logo.svg" alt="Logo" />
                <img src="@/assets/logo-text.svg" alt="Logo text" />
              </a>
              <p>
                Мы возмем на себя заботу о Вашей IT-инфраструктуре, мы -
                профессионалы своего дела.
              </p>
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Полезные ссылки</h4>
              <ul>
                <li>
                  <i class="bi bi-chevron-right"></i>
                  <a href="#home">Главная</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i> <a href="#about">О нас</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>
                  <a href="#services">Услуги</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>
                  <a href="#" @click.prevent="showPriv">Конфиденциальность</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Услуги</h4>
              <ul>
                <li>
                  <i class="bi bi-chevron-right"></i>
                  <a href="#services">Web поддержка</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>
                  <a href="#services">Web разработка</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>
                  <a href="#services">IT поддержка</a>
                </li>
              </ul>
            </div>

            <div
              class="col-lg-3 col-md-12 footer-contact text-center text-md-start"
            >
              <h4>Контакты</h4>
              <p><strong>Email:</strong> info@akerio.ru</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright">
          © Copyright <strong><span>AkeRio</span></strong>
        </div>
        <div class="credits">
          Разработка <a href="https://akerio.ru/">AkeRio</a>
        </div>
      </div>
    </footer>

    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>

    <v-dialog v-model="privacyWin" width="70%">
      <PrivacyView @closeWindow="closeWindow" />
    </v-dialog>

    <v-overlay :value="overlay" z-index="1040">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="cookieUse" timeout="-1">
      МЫ ИСПОЛЬЗУЕМ COOKIE. Продолжая использовать наш сайт, вы даете согласие
      на обработку файлов cookie. Если вы не хотите, чтобы ваши данные
      обрабатывались, покиньте сайт.
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="cookieUse = false">
          Понятно
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios'
import PrivacyView from '@/components/PrivacyView'

const select = (el, all = false) => {
  el = el.trim()
  if (all) {
    return [...document.querySelectorAll(el)]
  } else {
    return document.querySelector(el)
  }
}

const onscroll = (el, listener) => {
  el.addEventListener('scroll', listener)
}

const on = (type, el, listener, all = false) => {
  if (all) {
    select(el, all).forEach(e => e.addEventListener(type, listener))
  } else {
    select(el, all).addEventListener(type, listener)
  }
}

const scrollto = el => {
  let header = select('#header')
  let offset = header.offsetHeight

  if (!header.classList.contains('header-scrolled')) {
    offset -= 10
  }

  let elementPos = select(el).offsetTop
  window.scrollTo({
    top: elementPos - offset,
    behavior: 'smooth',
  })
}

export default {
  name: 'HomeView',
  components: { PrivacyView },
  data: () => ({
    success: false,
    overlay: false,
    navbarLinks: null,
    privacyWin: false,
    cookieUse: true,
    data: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
  }),
  watch: {
    cookieUse(newVal) {
      localStorage.setItem('stateCookieUse', JSON.stringify(newVal))
      console.log('newVal', newVal)
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('load', this.navbarLinksActive)
    onscroll(document, this.navbarLinksActive)
    this.checkProperties()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.navbarLinks = select('#navbar .scrollto', true)

    on('click', '.mobile-nav-toggle', function () {
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })

    on(
      'click',
      '.scrollto',
      function (e) {
        if (select(this.hash)) {
          e.preventDefault()

          let navbar = select('#navbar')
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            let navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
          }
          scrollto(this.hash)
        }
      },
      true
    )
  },
  methods: {
    checkProperties() {
      const u = JSON.parse(localStorage.getItem('stateCookieUse')) ?? null
      this.cookieUse = u === null
    },

    showPriv() {
      this.privacyWin = true
    },

    closeWindow() {
      this.privacyWin = false
    },

    async submitForm() {
      if (this.success) return
      this.overlay = true
      const formData = { ...this.data }
      try {
        await axios.post('https://api.akerio.ru/email/send', formData)
      } finally {
        this.data = {
          name: '',
          email: '',
          subject: '',
          message: '',
        }
        this.overlay = false
        this.success = true
      }
    },

    navbarLinksActive() {
      let position = window.scrollY + 200
      this.navbarLinks?.forEach(navbarLink => {
        if (!navbarLink.hash) return
        let section = select(navbarLink.hash)
        if (!section) return
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarLink.classList.add('active')
        } else {
          navbarLink.classList.remove('active')
        }
      })
    },

    handleScroll() {
      let header = document.querySelector('#header')
      let backToTop = document.querySelector('.back-to-top')
      if (window.scrollY > 100) {
        header.classList.add('header-scrolled')
        backToTop.classList.add('active')
      } else {
        header.classList.remove('header-scrolled')
        backToTop.classList.remove('active')
      }
    },
  },
}
</script>

<style>
.v-application a:not(.active):not(.getstarted):not(.btn-get-started) {
  color: #013289 !important;
}
.disabled {
  background-color: cornflowerblue !important;
}
</style>
